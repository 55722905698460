@import "../../../assets/styles/variables";


.passwordRequirements {
    color: $body-color;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    line-height: 2.1rem;
    margin: 2.6rem 0 3rem;
    padding: 0 1rem;

    p {
        font-weight: 600;
        margin-bottom: 0.7rem;
    }

    ul {
        margin-left: 2rem;

        li {
            margin-bottom: 0.7rem;
        }
    }
}

.btnWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;

    button:first-of-type {
        margin-right: 1rem;
    }
}
