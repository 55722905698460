@import './../../assets/styles/variables';

.contactDetails {
    font-size: $base-font-size;
    line-height: 2.1rem;

    .phone {
        font-weight: 600;
    }
}
