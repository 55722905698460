.title {
    color: $basic-text-color;
    font-size: $medium-font-size;
    font-weight: 500;
    margin-bottom: 0;

    &.main {
        font-weight: 600;
    }

    &.secondary {
        color: $gray-400;
        font-size: $small-base-font-size;
        font-weight: 400;
        margin: 1rem 0 2rem;
    }
}

.secondary-title {
    color: $basic-text-color;
    font-size: $base-font-size;
    font-weight: 400;

    &.description {
        color: $lighter-text-color;
    }

    &.edit {
        font-weight: 600;
    }

    &.small {
        font-size: $small-font-size;
    }
}

.action-button-wrapper {
    display: flex;
    position: relative;

    .action-popup {
        align-items: flex-start;
        background-color: $white-color;
        border-radius: 0.6rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        left: 0;
        padding: 2rem;
        position: absolute;
        top: 2rem;
        white-space: nowrap;
        z-index: 20;

        .action-popup-button-wrapper {
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            width: 100%;

        }
    }
}

// Styles for Price summary component in create pricing strategy modal - possible to reuse in other places
.price-summary-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 1rem;
    justify-content: stretch;
    padding: 2rem 0;

    &.price-strategy-input {
        padding: 2rem 0 0;

        .form-wrapper, .summary-wrapper {
            display: flex;
            flex: 4;
            flex-direction: column;
            gap: 1rem;

            .item {
                .title, .secondary-title {
                    margin-bottom: 0.5rem;
                }

                .activity-wrapper {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .form-wrapper {
            flex: 5;
        }
    }

    .item {
        display: flex;
        flex: 2;
        flex-direction: column;

        &.price-summary {
            border-right: thin solid $sidebar-menu-hover-color;
            flex: 3;
            padding-right: 2rem;
        }

        &.price-summary-details {
            padding-left: 2rem;

            .gross-price {
                display: flex;
                justify-content: flex-end;
            }
        }

        .price-summary-list {
            border-bottom: thin solid $sidebar-menu-hover-color;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding-bottom: 1rem;

            .summary-list-row {
                align-items: center;
                color: $basic-text-color;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .price-summary-item-name {
                    font-size: $small-font-size;
                    font-weight: 400;

                }

                .price-summary-item-value {
                    color: $basic-text-color;
                    font-size: $medium-font-size;
                    font-weight: 600;
                }
            }
        }
    }
}


.wizard-edit-host-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 6rem);
    padding: 2.5rem 2rem;



    .header-button {
        background-color: transparent;
    }

    .wizard-edit-host-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;

        .wizard-edit-host-header {
            align-items: center;
            display: flex;
            gap: 2rem;
            margin-bottom: 2rem;

            .wizard-edit-title {
                color: $sidebar-title-color;
                font-family: Montserrat, sans-serif;
                font-size: $m-font-size;
                font-style: normal;
                font-weight: 700;
                line-height: 2.4rem;
                margin: 0;
                text-transform: uppercase;
            }

            .wizard-edit-badges-wrapper {
                align-items: center;
                background-color: transparent;
                display: flex;
                gap: 2rem;

                .wizard-edit-badge {
                    border-radius: 0.5rem;
                    color: $primary-color;
                    font-size: $xxxm-font-size;
                    font-weight: 600;
                    letter-spacing: 0.2rem;
                    line-height: 1.8;
                    padding: 0.1rem 0.9rem;
                    text-transform: uppercase;
                }
            }

            .action-button-wrapper {
                display: flex;
                position: relative;

                .action-popup-wrapper {
                    .popup-backdrop {
                        height: 100%;
                        left: 0;
                        position: fixed;
                        top: 0;
                        width: 100vw;
                        z-index: 9;
                    }

                    .action-popup {
                        align-items: flex-start;
                        background-color: $white-color;
                        border-radius: 0.6rem;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        left: 0;
                        padding: 2rem;
                        position: absolute;
                        top: 2rem;
                        white-space: nowrap;
                        z-index: 20;

                        .action-popup-button-wrapper {
                            display: flex;
                            gap: 1rem;
                            justify-content: flex-end;
                            width: 100%;
                        }

                        .popup-header {
                            align-items: center;
                            display: flex;
                            flex: 1;
                            justify-content: space-between;
                            width: 100%;

                            button {
                                color: $black-color;
                                font-weight: 500;
                            }
                        }

                        .popup-body {
                            border-bottom: 1px solid rgba($black-color, 0.05);
                            border-top: 1px solid rgba($black-color, 0.05);
                            padding: 3.5rem 0 3rem;

                            .warning-wrapper {
                                background-color: rgba($error-color, 0.12);
                                border: 1px solid $error-color;
                                border-radius: 0.6rem;
                                color: $error-color;
                                font-size: $small-font-size;
                                margin-bottom: 2rem;
                                padding: 0.8rem 1rem;

                                svg {
                                    margin-right: 2rem;
                                }
                            }

                            .edit-form-control-subtitle {
                                color: $black-color;
                                font-size: $base-font-size;
                            }
                        }
                    }
                }
            }
        }

        .wizard-edit-host-content {
            display: flex;
            flex: 1;
            max-height: calc(100vh - 6rem);
            overflow: auto;

            .section-element {
                height: 70%;
            }

            .wizard-edit-nav-wrapper {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .nav-tabs {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;


                    .nav-link {
                        border-radius: 0.5rem;
                        display: block;
                        font-size: $medium-font-size;
                        font-weight: 600;
                        margin: 0.5rem 1rem;
                        text-decoration: none;

                        &:hover {
                            background-color: $sidebar-menu-hover-color;
                        }

                        &.active-edited-item-nav {
                            background-color: $sidebar-menu-hover-color;

                            & + .nav-sublinks-wrapper {
                                display: flex;
                            }
                        }


                    }

                    .nav-sublinks-wrapper {
                        background-color: $white-color;
                        display: none;
                        flex-direction: column;
                        list-style: none;
                        margin: 0;
                        padding: 0 0 0 2.5rem;
                        width: 100%;

                        .nav-sublink {
                            align-items: center;
                            background-color: transparent;
                            border-left: 0.2rem solid $sidebar-menu-hover-color;
                            cursor: pointer;
                            display: flex;
                            line-height: 2rem;
                            padding: 0.5rem 1rem;
                            text-decoration: none;

                            &:hover, &.active-sublink {
                                border-left-color: $sidebar-menu-item;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .wizard-edit-host-content-wrapper {
                display: flex;
                flex: 1;

                .details-edit-section-wrapper {
                    flex: 1;

                    .btn-underline {
                        font-size: $small-font-size;
                    }

                    .details-edit-section {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .details-edit-section-header {
                            font-weight: 600;
                            margin-bottom: 1rem;
                        }

                        .details-edit-form-control {
                            border-bottom: thin solid $sidebar-menu-hover-color;
                            max-width: 41vw;

                            &.edit-mode {
                                border: thin solid $lightest-text-color;
                                border-radius: 0.5rem;

                                .edit-form-control-body-wrapper {
                                    padding: 3rem 2rem 1rem;

                                    .form-control-wrapper {
                                        margin-bottom: 1rem;
                                    }
                                }

                                .berth-size-form {
                                    .form-control-wrapper {
                                        align-items: center;
                                        flex-direction: row;
                                        margin-bottom: 0;
                                        padding: 0.5rem 0;

                                        .custom-number-input-wrapper{
                                            .value-handler-button {
                                                width: 4rem;
                                            }

                                            .custom-number-input {
                                                max-width: 7rem;
                                                width: unset;
                                            }
                                        }

                                        .label-container {
                                            .form-label {
                                                display: flex;
                                            }
                                        }
                                    }
                                }

                                .edit-form-control-footer {
                                    border-top: thin solid $lightest-text-color;
                                    display: flex;
                                }
                            }

                            .location-list {
                                list-style: none;
                                margin-top: 2rem;
                                padding-left: 0;
                            }


                            .edit-form-control-body-wrapper {
                                display: flex;
                                flex-direction: column;
                                padding: 2rem 0;

                                &.status-switch {
                                    gap: 1rem;
                                }

                                .sizes-list {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;
                                    list-style: none;
                                    margin-top: 2rem;

                                    .size-list-item {
                                        align-items: center;
                                        color: $lighter-text-color;
                                        display: flex;
                                        flex-direction: row;
                                        font-size: $base-font-size;
                                        gap: 0.5rem;

                                        :first-child {
                                            font-weight: 400;
                                        }

                                        :last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }


                                .edit-form-control-header {
                                    align-items: center;
                                    display: flex;
                                    font-size: 1.6rem;
                                    justify-content: space-between;

                                    .header-button{
                                        background-color: transparent;
                                        color:$lightest-text-color;
                                    }
                                }

                                .edit-form-control-subtitle {
                                    font-size: $small-base-font-size;
                                }

                                .create-marina-wrapper {
                                    display: flex;
                                    gap: 1rem;

                                }

                            }

                            .edit-form-control-footer {
                                align-items: center;
                                display: none;
                                justify-content: flex-end;
                                padding: 1rem 2rem;

                                &.native {
                                    margin: 0 -2rem;
                                    padding: 0 2rem;
                                }
                            }
                        }

                        &:not(:first-child) {
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }
    }
}

.location-map-wrapper {
    .leaflet-container{
        aspect-ratio: 9/11;
        max-height: 33rem;
    }
}

.location-modal {
    .modal-body {
        padding: 1rem 4rem;
    }

    .modal-form {
        .title {
            color: $basic-text-color;
            font-size: $medium-font-size;
            font-weight: 600;
            margin-bottom: 0;
        }

        .secondary-title {
            color: $basic-text-color;
            font-size: $base-font-size;
            font-weight: 400;

        }

        .location-map-wrapper {
            margin-top: 1rem;
        }

        &.vessel-position {
            width: 100%;
        }
    }
}

@media (max-width: $breakpoint-small) {
    .wizard-edit-host-card {
        .wizard-edit-host-wrapper {
            .wizard-edit-host-header {
                flex-wrap: wrap;

                .action-button-wrapper {
                    .action-popup-wrapper {
                        .popup-backdrop {
                            background-color: rgba(34, 41, 74, 0.5);
                        }

                        .action-popup {
                            left: 1rem;
                            position: fixed;
                            right: 1rem;
                            top: 10vh;

                            .popup-body {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .wizard-edit-host-content {
                .wizard-edit-host-content-wrapper {
                    .details-edit-section-wrapper {
                        .details-edit-section {
                            .details-edit-form-control {
                                max-width: 100vw;
                            }
                        }
                    }
                }
            }
        }
    }
}
