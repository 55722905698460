@import "../../../assets/styles/variables";


.modalContainer {
    color: $body-color;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 400;
    line-height: 2.1rem;
    padding: 2rem;
    text-align: center;
}
