@import '../../../../../../assets/styles/variables';

.container {
    h2, h3 {
        font-size: $base-m-font-size;
        font-weight: 600;
    }

    .section {
        border-bottom: 1px solid rgba($black-color,0.05) ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 5rem;
        overflow: hidden;
        padding-bottom: 3rem;
        width: 63rem;


        p {
            font-size: $small-base-font-size;
        }

        .galleryHeader {
            display: flex;
            justify-content: space-between;
            width: 60rem;
        }

        .coverPhoto {
            border-radius: 0.5rem;
            height: 21rem;
            margin-top: 1rem;
            object-fit: fill;
            width: 32rem;

            &.placeholder {
                background-image: url('../../../../../../assets/images/placeholder-image.jpg');
                background-position: center;
                background-size: cover;
            }
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        padding: 0;

        li {
            list-style: none;
            margin-right: 2rem;
            position: relative;

            .galleryPhoto {
                border-radius: 0.5rem;
                height: 12.5rem;
                margin-bottom: 0.5rem;
                object-fit: fill;
                width: 19rem;
            }

            .deleteBtn {
                height: 3.5rem;
                position: absolute;
                right: 1rem;
                top: 1rem;
                width: 3.5rem;

                svg {
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    color: $error-color;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-small) {
    .container {
        .section {
            width: 100%;

            .galleryHeader {
                width: 100%;
            }

            .coverPhoto {
                width: 100%;
            }
        }
    }
}
