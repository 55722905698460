@import url("~leaflet/dist/leaflet.css");
@import url("~leaflet-draw/dist/leaflet.draw.css");
@import "~marine-panel-styles/normalize";
@import "~marine-panel-styles/panel/variables";
@import "~marine-panel-styles/panel/style";
@import "./leaflet.scss";
@import "./edit-wizard-host.scss";


.sr-only {
    border: 0 !important;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 0.1rem !important;
    margin: -0.1rem !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 0.1rem !important;
}

.horizontal-spacer {
    margin: 0 1rem;
}

p {
    margin-bottom: 0;
}
