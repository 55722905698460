@import './../../assets/styles/variables';

.marinaDetails {
    align-items: center;
    display: flex;

    .marinaCover {
        border-radius: 0.5rem;
        height: 100%; // 4.3rem;
        margin-right: 3.3rem;
        width: 6.3rem;
    }

    .title {
        color: rgba($black-color, 0.8);
        font-size: $base-font-size;
        line-height: 2.1rem;
    }
}

.actions {
    align-items: center;
    display: flex;

    .inputContainer {
        position: relative;

        input {
            border: 1px solid $input-border-color;
            border-radius: 0.5rem;
            height: 100%;
            margin-right: 1.7rem;
            padding: 0.6rem 1.5rem;

            &::placeholder {
                color: $text-muted;
                font-family: Montserrat, sans-serif;
                font-size: $small-base-font-size;
                font-weight: 500;
            }
        }

        &::before {
            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD/SURBVHgBrZOhDsIwEIYPmAA5iWSCZEgscgKxV0DCo/BCJOCGA4ldgpkgYWIkLEGAQPAfvYVStgbI/uRL1l339653I6pBjZJ3bTAEXVmfwQ7kVSYtY+2DGfDABTigDwI5MCkzcbTnHpiADViDmxbjzELJcmnLZApisAB3Y18KMjFKzNKaWhYuiKhasRj4ZqAw6Ur6Odl1pNeFf5iwrvSnChOu2S07xZAne0tNuFaeh8BiUMxObAb07vAJY9Ah1Qm9xSOJHUi1/03mxPLNh2KUSnYDUvMRS5xNoqpMWCfZnMmHrD1YgS2p7oVkmd5vxXczJ1XiUw79Lv4ZeRxcqlMPuM8xVlXlpoEAAAAASUVORK5CYII=');
            position: absolute;
            right: 3rem;
            top: 0.8rem;
        }
    }
}
