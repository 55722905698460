@import './variables';

.leaflet-container {
    height: 100vh;
    width: 100%;
}

.map-container {
    position: relative;

    .berth-info-card {
        align-items: stretch;
        background-color: $base-background;
        border: thin solid $input-border-color;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        min-height: 50rem;
        padding: 1rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 20vw;
        z-index: 10000;
    }

}

.leaflet-container {
    .leaflet-map-pane {
        .disabled-polygon {
            color: $info-color;
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
        }

        .leaflet-marker-pane {
            .leaflet-div-icon {
                background-color: rgba($green, 0.4);
                border-color: $input-border-color;
                border-radius: 50%;
            }
        }
    }
}
