@import './../../../../assets/styles/variables';


.actions {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    input {
        border: 1px solid $input-border-color;
        border-radius: 0.5rem;
        height: 100%;
        margin-right: 1.7rem;
        padding: 0.6rem 1.5rem;
    }
}
