@import '../../../assets/styles/variables';

.container {
    padding: 4rem;

    ul {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-top: 1rem;
        padding: 0;

        li {
            list-style: none;
            margin-right: 2rem;
            position: relative;

            .galleryPhoto {
                height:12.5rem;
                margin-bottom: 1rem;
                object-fit: cover;
                width: 15rem;
            }

            .deleteBtn {
                background: rgba(255,255,255,0.2);
                border-radius: 0.5rem;
                cursor: pointer;
                height: 3.5rem;
                padding: 0.5rem;
                position: absolute;
                right: 1rem;
                top:1rem;
                width: 3.5rem;

                &:hover {
                    color: $error-color;
                }
            }

            .changeCoverPhoto {
                height: 3.5rem;
                padding: 0.5rem;
                position: absolute;
                right: 0;
                top: 0;
                width: 3.5rem;

                &:hover {
                    color: $error-color;
                }
            }

            &.noData {
                font-size: $base-font-size;
            }
        }
    }

    .content {
        p {
            font-size: $base-font-size;
        }
    }

    .uploader {
        position: relative;

        div {
            div {
                div {
                    img {
                        height:12rem ;
                        width: 19rem;
                    }
                }
            }
        }

        input {
            font-size: $base-font-size;
            padding-top: 1rem;
        }

        input+button {
            display: none;
        }
    }
}
